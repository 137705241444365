<template>
  <div class="home">
    <Header></Header>
    <Content></Content>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header/Herder.vue'
import Content from '@/components/Content/Content.vue'
import Footer from '@/components/Footer/Footer.vue'
export default {
  name: 'Home',
  components: {
    Header,
    Content,
    Footer,
}
}
</script>
<style>

</style>>
