<template>
  <div class="mobilefooter">
    <div class="footerlogo">
      <img src="../img/mobilelogo.png" alt="">
    </div>
    <div class="footerlink">
      <ul class="link_list">
        <li class="link_item" @click="gotoabout">About Us</li>
        <li class="link_item" @click="gotoview('mservice')">Terms of Service</li>
        <li class="link_item" @click="gotoview('mprivacypolicy')">Privacy Policy</li>
        <li class="link_item" @click="gotocontact">Contact Us</li>
        <li class="link_item" @click="gotoview('mrefundpolicy')">Refund Policy</li>
        <!-- <li class="link_item" @click="gotoview('mcancellationpolicy')">Cancellation Policy</li> -->
      </ul>
    </div>
    <div class="footertext">
      <p>FELICE CASA TECHNOLOGY PTE. LTD.</p>
      <p style="margin:.1rem 0 .36rem">10 ANSON ROAD #10-21 INTERNATIONAL PLAZA SINGAPORE (079903)</p>
      <p>© 2022  StarMake All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gotoabout() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobileabout: 1
        }
      })
      return
    },
    gotocontact() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobilecontact: 1
        }
      })
      return
    },
    gotoview(path) {
      this.$router.push(`${path}`)
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped src="./mobilefooter.scss">

</style>