<template>
  <div class="tos">
    <Detail :deteil_title="'Refund Policy'"></Detail>
    <div class="wrap detail_text">
      <p>The event any in-app purchases and virtual items require a transaction with a third party service provider
        (including payment service providers), the Visitor agrees to abide by the terms of use and other relevant
        instruction of such third party service provider.
      </p>
      <p>The Visitor shall be solely responsible for any payments made from his bank account in this relation. The
        Company shall not be a party to such transaction or agreement, and shall not be responsible for any actions or
        omission, claims of misconduct, fraud or negligence pertaining to the transaction.
      </p>
      <p>The Company accepts no obligation and responsibility for the use of the payment option by the third party
        service provider. Once payment is made by the Visitor, the Visitor shall not have the further right to cancel
        and redeem, or require a refund of the payment already made by him.</p>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Detail from '@/components/Detail/Detail.vue'
import Footer from '@/components/Footer/Footer.vue'
export default {
  components: {
    Detail,
    Footer
  }

}
</script>

<style>

</style>