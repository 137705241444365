<template>
  <div class="mobile_home">
    <NavlistVue v-if="navshow"></NavlistVue>
    <Mobileheader></Mobileheader>
    <Mobilecontent></Mobilecontent>
    <Mobilefooter></Mobilefooter>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import NavlistVue from '@/components/Navlist/Navlist.vue'
import Mobileheader from '@/components/MobileHeader/Mobileheader.vue'
import Mobilecontent from '@/components/MobileContent/Mobilecontent'
import Mobilefooter from '@/components/MobileFooter/Mobilefooter.vue'
export default {
  components: {
    Mobileheader,
    Mobilecontent,
    Mobilefooter,
    NavlistVue
  },
  computed:{
    ...mapState(['navshow'])
  }

}
</script>

<style lang="scss" scoped src="./mobile.scss">
</style>