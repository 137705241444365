<template>
  <div class="tos">
    <Detail :deteil_title="'Terms of service'"></Detail>
    <div class="wrap detail_text">
      <p> By accessing and visiting this website, you acknowledge that you have read and understood these customer terms
        (“customer terms or “terms”).
      </p>
      <p>Please ensure that you read and understand all of these customer terms before you start using the website, as
        you will be bound by these customer terms.
      </p>
      <p>These customer terms are between the users of the website (referred to as "you" or "your" or "yourself" or
        "user" or “customers”), and FELICE CASA TECHNOLOGY PTE. LTD.(“company”), a company incorporated under the
        companies act, 2013 having its registered address at 10 ANSON ROAD #10-21 INTERNATIONAL PLAZA SINGAPORE (079903)
        and shall be a legally binding agreement blietween you and the company. You, and FELICE CASA TECHNOLOGY PTE.
        LTD. shall each be referred to individually as “party” and collectively the “parties”.
      </p>
      <p>If you do not agree with any of these customer terms, you must immediately cease accessing and using the
        website and the services being provided under these customer terms. Your acceptance of these customer terms will
        operate as a binding agreement between you and FELICE CASA TECHNOLOGY PTE. LTD. in respect of your use of the
        website, and/or subscription of available services.
      </p>
      <p>BACKGROUND
      </p>
      <p>Company owns and operates the website and the Application “starmake”, connected to or mentioned herein. The
        Company, under this website/social application provides its users with the most simple and reliable way to
        connect with friends nearby or around the world. The Company’s services allow users to send free text, voice,
        video, photo messages, and stickers to their friends. It is intended to be a fun place to visit and it is
        important to the company that it remain a safe and friendly environment for all users and in this regard, you
        agree that you will only use agree to use the services in a manner consistent with its purpose and which is in
        accordance with the Terms.
      </p>
      <p>THE PARTIES AGREE AS FOLLOWS
      </p>
      <p>1. DEFINITIONS AND INTERPRETATION
      </p>
      <p>1.1 Unless the context requires otherwise, capitalized terms in these Customer Terms have the following
        meaning:
      </p>
      <p>1.1.1 "Account" means the account created by You on the Website and/or Application for accessing the Services.
      </p>
      <p>1.1.2 “Affiliate” means, in relation to any entity, another company or entity that either directly or
        indirectly, through one or more intermediaries, Controls, is Controlled by, or is under common Control with that
        entity. For purposes of this definition, “Control” means, with regard to any company or entity, (i) the legal or
        beneficial ownership, directly or indirectly, of 50% or more of the shares (or other ownership interests if not
        a corporation) of the entity or company through voting rights or through the exercise of rights pursuant to
        agreement; or (ii) the possession, directly or indirectly, of the power to direct or cause the direction of the
        management or policies of the entity or company.
      </p>
      <p>1.1.3 "Applicable Laws" means (in any relevant jurisdiction) all laws, including rules of common law,
        principles of equity, statutes, regulations, proclamations, ordinances, by-laws, rules, statutory rules of an
        industry body, statutory and mandatory codes or guidelines or the like, writs, orders, injunctions, judgments
        applicable from time to time.
      </p>
      <p>1.1.4 "Application" means such features of the “starmake” mobile application or other programs, software,
        mobile applications owned (or licensed to) by the Company, and other URLs as may be specified by the Company
        from time to time. The Application includes the electronic interface where the Subscriber account is accessible,
        the login credentials (user ID and password) for which will be provided by Company to the Subscriber.
      </p>
      <p>1.1.5 "Area of Operation" means the area in which these Customer Terms are accepted by You, being the territory
        of India.
      </p>
      <p>1.1.6 "Business Day" means any day excluding Sunday or public holiday in the Area of Operation.
      </p>
      <p>1.1.7 “Customer Content” means images, sounds, videos, data, text, information or any other materials or
        content the user creates, uploads, publishes or propagates through the company’s services
      </p>
      <p>1.1.8 “Force Majeure” has the meaning set out in Clause 15.1.
      </p>
      <p>1.1.9 “Company” has the meaning given in the “Parties” section on the front page of these Customer Terms.
      </p>
      <p>1.1.10 “Linked sites” means the links used in or through the services to other websites or services, provided
        solely as a convenience to the users.
      </p>
      <p>1.1.11 “Subscriber” has the meaning given in the “Parties” section on the front page of these Customer Terms.
      </p>
      <p>1.1.12 “Prohibited Content” means the images, sounds, videos, data, text, information or any other materials or
        content (collectively, “Content”) that: (a) violates any rule, law, regulation or policy; (b) harms national
        interests, endangers national security or divulges national secrets; (c) incites ethnic or racial discrimination
        or hatred; (d) undermines the social stability; (e) contains obscenity, sexual connotation, pornography,
        gambling, violence, murder, or terror; (f) insults or defames others or infringes others’ lawful rights and
        interests; or (g) contains abusive or threatening information.
      </p>
      <p>1.1.13 “Privacy Policy” means the privacy policy available on the website, as amended by the Company from time
        to time.
      </p>
      <p>1.1.14 “Permitted Information” means information such as a user’s name, username, address etc. which the user
        voluntarily submits to the website or application while registering for the same.
      </p>
      <p>1.1.15 “Registration Data” means Subscriber name, email address, telephone number and other information
        (including personal data) that is provided to the Company for registering on the website and/or Application.
      </p>
      <p>1.1.16 “Services” means the technology services the Company or its affiliates provide such as “starmake” or
        other applications/services operated by FELICE CASA TECHNOLOGY PTE. LTD. and its affiliates.
      </p>
      <p>1.1.17 “Virtual Items” means any other form virtual currency (as updated by the Company on its
        website/application from time to time) which enables the user to purchase, use and engage in services offered on
        the company’s app/website. These virtual items can be bought used, redeemed and/or purchased through the
        website/application or through the companies affiliate third party service providers.
      </p>
      <p>1.1.18 “You / Your” means you, the user and Subscriber of the Website and the Services.
      </p>
      <p>2. INTERPRETATION
      </p>
      <p>2.1 Unless the context requires otherwise, in these Customer Terms:
      </p>
      <p>2.1.1 the singular includes the plural and vice versa;
      </p>
      <p>2.1.2 the words 'such as', ‘for example’, 'including', 'particularly' and similar expressions are not used as,
        nor are intended to be, interpreted as words of limitation;
      </p>
      <p>2.1.3 a reference to: </p>
      <p>2.1.1 the singular includes the plural and vice versa; </p>
      <p>2.1.5 If a period of time is calculated from a particular day, act or event (such as the giving of a notice),
        it is to be calculated exclusive of that day, or the day of that act or event; and </p>
      <p>8.10 The company services may contain an auto-reply function. You agree that you will not use any robot,
        spider, scraper, or other automated means to access, bypass the company’s services for any purpose without our
        express written permission. </p>
      <p>12.1 Users have the option to purchase or buy “virtual items”, which may be used and or redeemed on the
        platform to play games, gift items and make further in-app purchases.
      </p>
      <p>(a) a person includes an individual, partnership, joint venture, trust, corporation or any other entity or
        association whether or not it is incorporated or has a separate legal identity;
      </p>
      <p>(b) a Party includes that Party's executors, administrators, successors, and permitted substitutes and assigns,
        including any person taking by way of novation;
      </p>
      <p>(c) “Rupee” is to the lawful currency of the Republic of India;
      </p>
      <p>2.1.4 In determining the time of day, the relevant time of day is the time in the Area of Operation;
      </p>
      <p>2.1.5 If a period of time is calculated from a particular day, act or event (such as the giving of a notice),
        it is to be calculated exclusive of that day, or the day of that act or event; and
      </p>
      <p>2.1.6 No rule of construction applies to the disadvantage of a Party because that Party was responsible for the
        preparation of these Customer Terms or any part of them.
      </p>
      <p>3. REGISTRATION AND USAGE
      </p>
      <p>3.1 Authorized Users. Your access to and use of the company’s Services are subject to all applicable
        international, federal, state and local laws and regulations. You represent and warrant that you will not use
        the Service in any manner or for any purposes that are unlawful or prohibited by this Agreement.
      </p>
      <p>3.2 The Services are not meant for persons under the age of 18. If you are under 18 years of age, then please
        do not use these Services.
      </p>
      <p>3.3 Only use an authorized telephonic or internet network to access and use the Website. When using the
        Website, the Subscription Services and the Services, standard messaging charges, data charges and/or voice
        charges (as applicable) may be imposed by Your Device provider and the same are Your responsibility.
      </p>
      <p>3.4 You are solely responsible for maintaining the confidentiality of Your Registration Data and Application
        login credentials, and will be liable for all activities and transactions, and any other misuse of the
        Application, that occurs through Your Account (whether initiated by You or any third party), except to the
        extent caused or contributed by Company.
      </p>
      <p>3.5 You must also notify the Company immediately if You cannot access Your Account, you know of or suspect any
        unauthorized access or use of Your Registration Data, login details or Account, or the security of Your Account
        has been compromised in any way.
      </p>
      <p>3.6 The Company and/or any of its Third-Party Service Providers may suspend access and Service subscriptions
        entered into if:
      </p>
      <p>3.6.1 The Registration Data or any other information provided by You is false, or You cease to satisfy the
        Eligibility Requirements;
      </p>
      <p>3.6.2 The security of Your Account has been compromised in any way; or
      </p>
      <p>3.6.3 You have not complied with any of the requirements in this clause.
      </p>
      <p>3.7 The Company and/or any of its Third-Party Service Providers may block, suspend, alter or update the
        Website, the Streaming Services and/or the Services at any time (including without notice):
      </p>
      <p>3.7.1 To make improvements to the Website, the Booking Services and/or the Services (including the security of
        the Website, the Booking Services and/or the Services);
      </p>
      <p>3.7.2 as required by Applicable Law; or
      </p>
      <p>3.7.3 To protect a legitimate business interest.
      </p>
      <p>4. SPECIFIC FEATURES AND ADDITIONAL SERVICES.
      </p>
      <p>4.1 When using the company’s services, you may be subject to additional guidelines and/or terms and conditions
        (the “Guidelines”) as and when applicable, to specific services and features which may be posted from time to
        time on the platform.
      </p>
      <p>4.2 All such Guidelines are hereby incorporated by reference into these Terms and Conditions of use. In
        addition, your use is further governed by the Company’s Privacy Policy available (the “Privacy Policy”), which
        is hereby incorporated by reference into these Terms and Conditions and you consent to the collection, use and
        disclosure of any personal information in accordance with the Privacy Policy
      </p>
      <p>5. LIMITED LICENSE
      </p>
      <p>5.1 Subject to the terms and conditions herein, FELICE CASA TECHNOLOGY PTE. LTD. here by grants you a limited,
        revocable, non-transferable, non-exclusive and non-transferable license to use its website/application and other
        related services (including all the updates and/or upgrades as and when provided)
      </p>
      <p>5.2 The said license is solely for private and non-commercial use/purposes and is subject to the user adhering
        to these Terms and Conditions, Privacy Policy and any and all additional guidelines as and when updated by the
        Company.
      </p>
      <p>6. INTELLECTUAL PROPERTY RIGHTS
      </p>
      <p>6.1 The Website and all associated intellectual property rights (“IP”) remain the property of the Company or
        its third-party licensors. Except as expressly stated, nothing in these Customer Terms grants the Customer any
        rights in or related to the IP, and all rights not expressly granted to the Customer are reserved by the
        Company.
      </p>
      <p>6.2 The website/application and other related services are owned and operated by the company. The content,
        visual interfaces, information, graphics, design, compilation, computer code, products, software, algorithms,
        services, and all other elements of the Services (hereinafter referred to as “Company Materials”) that are
        provided by the company are protected under the relevant Copyright, Trademark, Patent laws/statutes,
        international conventions, and all other relevant intellectual property rules and regulations.
      </p>
      <p>6.3 All company materials contained on the website/application are the copyrighted property of FELICE CASA
        TECHNOLOGY PTE. LTD. or affiliated companies and/or third-party licensors.
      </p>
      <p>6.4 The Company materials does not include any User/Customer Content or content from third party sites
        regardless of the fact that the company’s website/application provides a link to access such content.
      </p>
      <p>6.5 The Customer must not:
      </p>
      <p>6.5.1 Copy, reproduce, modify, create derivative works of, decompile, reverse engineer, or attempt to derive
        the composition or underlying information, structure or ideas of, any IP;
      </p>
      <p>6.5.2 Breach, disable, tamper with, or develop or use (or attempt) any workaround for any security measure
        provided on the website and/or Application;
      </p>
      <p>6.5.3 Use any IP in a way that infringes or misappropriates a third party’s intellectual property rights or
        moral rights;
      </p>
      <p>6.5.4 Distribute, disclose or allow use of any IP by any third party in any format, through any timesharing
        service, service bureau, network or by any other means;
      </p>
      <p>6.5.5 Merge or combine any IP with any other technology not provided by the Company or any; or remove any
        proprietary notice language on any copies of any IP.
      </p>
      <p>6.6 The Customer is solely responsible for any notes, messages, e-mails, billboard postings, photos, drawings,
        profiles, opinions, ideas, images, videos, audio files or other material(s) or information posted or transmitted
        through the Website and/or Application or Device by or on behalf of the Customer (“Customer Content”).
      </p>
      <p>6.7 The Customer must ensure that the Content is not unlawful and does not infringe any third party’s rights
        (including intellectual property rights), and the Customer must not:
      </p>
      <p>6.7.1 publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing,
        obscene, indecent or unlawful topic, blasphemous, pornographic, libellous, invasive of another's privacy,
        hateful, or racially, ethnically objectionable, disparaging, or otherwise unlawful material or in-formation, or
        any material relating to or encouraging money laundering or gambling;
      </p>
      <p>6.7.2 upload files that contain software or other material protected by intellectual property laws (or by
        rights of privacy or publicity) unless You own or control the rights thereto or have received all necessary
        consents;
      </p>
      <p>6.7.3 upload files that contain viruses, corrupt files, or any other similar software or programs that may
        damage the operation of another computer or electronic device;
      </p>
      <p>6.7.4 download any file posted by another user that You know, or reasonably should know, cannot be legally
        distributed in such manner;
      </p>
      <p>6.7.5 falsify or delete any author attributions, legal or other proper notices or proprietary designations or
        labels of the origin or source of software or other material contained in a file that is uploaded;
      </p>
      <p>6.7.6 deceive or mislead the addressee about the origin of a message or communicate any information which is
        grossly offensive or menacing in nature;
      </p>
      <p>6.7.7 harvest or otherwise collect information about others, including e-mail ad-dresses, without their
        consent.
      </p>
      <p>7. USER CONTENT
      </p>
      <p>7.1 Prohibited Content. You shall not use the company’s services to create, upload, download, copy, publish or
        propagate any images, sounds, videos, data, text, information or any other materials or content (collectively,
        “Content”) that: (a) violates any rule, law, regulation or policy; (b) harms national interests, endangers
        national security or divulges national secrets; (c) incites ethnic or racial discrimination or hatred; (d)
        undermines the social stability; (e) contains obscenity, sexual connotation, pornography, gambling, violence,
        murder, or terror; (f) insults or defames others or infringes others’ lawful rights and interests; or (g)
        contains abusive or threatening information.
      </p>
      <p>7.2 License to User Content. You hereby grant the Company a worldwide, non-exclusive, irrevocable,
        royalty-free, fully-paid, perpetual, sublicensable (through multiple tiers), fully transferable license to use,
        distribute, reproduce, create derivative works from, publish, translate, publicly perform and publicly display
        any Content that you upload or publish to the company’s services (collectively, “Your Content”), in any format
        or medium now known or later developed for any purpose. FELICE CASA TECHNOLOGY PTE. LTD. can use the
        customer/user content in/on the website/mobile application and in other products or services, at the company’s
        sole discretion and can transfer the license or authorization of using such information and content to its
        related companies and partners once again, at their sole discretion without the need to obtain the users
        consent.
      </p>
      <p>7.3 The Company reserves the right to display advertisements/promotional material(s) and sponsorships in
        connection with User/Customer Content.
        (Note: You are not entitled to any payment or other compensation for Company’s use of Your Content)
      </p>
      <p>7.4 The company has the right to remove, at its sole discretion and without notice to you, Your Content, if it
        infringes others’ rights and interests. The company further has the right to suspend or terminate a user’s
        access to the services if the same is in violation of copyright law or other intellectual property law.
      </p>
      <p>7.5 All liabilities and damage with respect to any and all claim(s) raised by the said third party(s) with
        respect to Customer/User content shall be assumed by the user and they shall compensate the Company for all
        losses and damages it incurs therefrom, including but not limited to economic losses and business losses.
      </p>
      <p>7.6 You should not, send any confidential or proprietary information to the Company, unless required as per the
        applicable laws, notwithstanding the “Permitted Information” (as per clause 1.1.12) already submitted by you.
      </p>
      <p>7.7 Subject to the Company handling your personal information in accordance with the existing Privacy Policy,
        none of your Content will not be considered confidential or proprietary.
      </p>
      <p>8. USER OBLIGATIONS.
      </p>
      <p>8.1 Restrictions; As a pre-condition to your use of the Service; you will not use the Services for any purpose
        that is unlawful or prohibited by these Terms and Conditions herein.
      </p>
      <p>8.2 Access to the Company Materials and connected services from territories wherein the same is illegal is
        strictly prohibited. Users are responsible for complying with all local rules, laws, regulations and policies,
        including, without limitation, rules with respect to intellectual property rights, the internet, technology,
        data, email, and/or privacy.
      </p>
      <p>8.3 Use of any of the Company Materials for any other purpose apart from what is permitted herein is
        prohibited.
      </p>
      <p>8.4 You will not use the company service in any manner that, in our sole discretion could damage, disable,
        overburden, impair or interfere with any other party’s use of the Service.
      </p>
      <p>8.5 You will not take any action that imposes an unreasonable or disproportionately large load on the company’s
        infrastructure.
      </p>
      <p>8.6 You will not intentionally interfere with or damage the operation of the company’s services or any user’s
        enjoyment of it, by any means, including uploading or otherwise disseminating viruses, worms, or other malicious
        code.
      </p>
      <p>8.7 You will not remove, circumvent, disable, damage or otherwise interfere with any security-related features
        of the company’s services, features that prevent or restrict the use or copying of any content accessible, or
        features that enforce limitations on the use of the company’s services.
      </p>
      <p>8.8 You will not attempt to gain unauthorized access to the company’s services, or any part of it, other
        accounts, computer systems or networks connected to it or any part of it, through hacking, password mining or
        any other means or try to interfere or attempt to interfere with the proper working of the company’s service
        and/or the activities conducted on the company’s services.
      </p>
      <p>8.9 You will not obtain or attempt to obtain any materials or information through any means not intentionally
        made available by the company. You agree neither to modify in any manner or form, nor to use modified versions
        of the company’s services, including (without limitation) for the purpose of obtaining unauthorized access to
        the same
      </p>
      <p>8.10 The company services may contain an auto-reply function. You agree that you will not use any robot,
        spider, scraper, or other automated means to access, bypass the company’s services for any purpose without our
        express written permission.
      </p>
      <p>8.11 You will not utilize framing techniques to enclose any trademark, logo, or other company materials without
        our express written consent.
      </p>
      <p>8.12 You will not use any meta tags or any other “hidden text” utilizing the company’s name or trademarks
        without our express written consent.
      </p>
      <p>8.13 You will not deep-link to the company’s services and will remove any links that the company finds
        objectionable in its sole discretion. You will not use our logos, graphics, or trademarks as part of the link
        without our express written consent.
      </p>
      <p>8.14 You will not send junk mail to other users of the service, including, but not limited to unsolicited
        advertising, promotional materials or other solicitation material, bulk mailing of commercial advertising, chain
        mail, informational announcements, charity requests, and petitions for signatures.
      </p>
      <p>8.15 You indemnify us against all Loss we suffer or incur as a direct or indirect result of your failure to
        comply with these Terms, including any failure of a person who accesses and uses our Website by using your User
        ID.
      </p>
      <p>8.16 Representation and Warranty; You represent, warrant and covenant that at all times: (i) the Your Content
        is legal, does not infringe any third party’s intellectual property, right of reputation, right of name, right
        of privacy, moral rights and other lawful rights and interests; (ii) you own or have the necessary licenses,
        rights, consents and permissions for the use of the your Content; (iii) you authorise the company’s use of the
        your content for its purposes.
      </p>
      <p>FEEDBACK
      </p>
      <p>9.1 You may, but are not required to, provide suggestions, comments, ideas, or know-how, in any form with
        respect to the services to the company.
      </p>
      <p>9.2 Any Feedback shall not be considered your confidential information and may be used by the company for any
        purpose.
      </p>
      <p>9.3 There shall be no obligation to provide compensation for use of Feedback.
      </p>
      <p>10. THIRD PARTY SITES
      </p>
      <p>10.1 The Service may include links to other websites or services solely as a convenience to users (“Linked
        Sites”).
      </p>
      <p>10.2 The Company does not endorse any Linked Sites or the information, material, products or services contained
        on Linked Sites or accessible through Linked Sites. Furthermore, the company makes no express or implied
        warranties with regard to the information, material, products, or services that are contained on or accessible
        through Linked Sites.
      </p>
      <p>10.3 Access and use of linked sites, including the information, material, products, and services on linked
        sites or available through linked sites, is solely at your own risk.
      </p>
      <p>10.4 Your correspondence or business dealings and/or participation and/or engagement with the advertisers/third
        party(s) found on or through the platform are solely between you and such advertiser. You agree that the company
        will not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings
        or as the result of the presence of such advertisers on its website/application or platform.
      </p>
      <p>10.5 The company may make changes to or discontinue any of the content or services available on the
        Platform/website/mobile application at any time, without notice. The content or services may be out of date, and
        he company makes no commitment to update these materials.
      </p>
      <p>11. REFUND POLICY
      </p>
      <p>11.1 The company does not have a refund policy after a successful payment is made and confirmed. Upon making a
        successful payment on the platform, the user foregoes any rights to the membership/subscription fee paid,
        virtual currency bought; unless the company is directed to do so as per Law or by an order of a competent court
        or any other such authority.
      </p>
      <p>12. VIRTUAL ITEMS
      </p>
      <p>12.1 Users have the option to purchase or buy “virtual items”, which may be used and or redeemed on the
        platform to play games, gift items and make further in-app purchases.
      </p>
      <p>12.2 These virtual items may be purchased by a user in the following ways;
      </p>
      <p>i. via the authorised payment gateway channel(s) that are part of/built in to the “Google Play Games” and/or
        Google play app store.
      </p>
      <p>ii. via Third Party Partners of FELICE CASA TECHNOLOGY PTE. LTD., a list and details of whom will be updated by
        the company on its website/mobile application from time to time.
      </p>
      <p>12.3 Unless otherwise specified, and subject to clause 12, these virtual items can be used and have value only
        on the Company’s website/mobile application and other related services.
      </p>
      <p>12.4 The virtual items hold no purchasing power and have no legal backing and/or value in the commercial and/or
        public markets/marketplaces.
      </p>
      <p>12.5 As the Company has a no refund policy (clause 12), upon a successful transaction the user foregoes any
        rights to the fee/money paid in order to purchase the virtual items on the platform
      </p>
      <p>12.6 The virtual items can be used in order to purchase value-added services, items, tokens and other
        services/items offered by the Company on its platform, such as sending gifts and playing games etc. Specific
        price/virtual item related information, exchange rates, and usage policy will be determined by the company at
        their sole discretion and information about the same will be provided on our service interface.
      </p>
      <p>12.7 Unless otherwise provided hereof, virtual items may not be used for any other purpose(s), including to
        conduct virtual item(s) transactions with third parties, or trading on third-party platforms other than those of
        the company and or its affiliates. If you violate the foregoing requirement, we will not be liable for any
        losses to you or the third party. Furthermore, on suspicion of your Account being is involved in such suspicious
        activity(s), we reserve the right to suspend the said account and the user will forego his/her rights over the
        virtual items, until relevant measures are taken in accordance with this agreement.
      </p>
      <p>12.8 You can recharge your virtual items through channels allowed by us (clause 13.2). The exchange rate
        between virtual items and legal currency varies in accordance with the purchasing channel, information on the
        same will be displayed and updated from time to time on the Company’s platform, website/application.
      </p>
      <p>12.9 You agree not to recharge or funnel money into our system through illegal or unauthorized channels. If you
        violate the rules and transact via illegal means on our interface in order purchase virtual items, we do not
        take responsibility for the same and will not be able to guarantee that the transaction will be successful.
      </p>
      <p>12.10 Under no circumstance, can a user or individual exchange the virtual items for legal
        denominations/depository notes and/or other forms of legal, backed currency.
      </p>
      <p>12.11 The Company will not take any responsibility, nor will it compensate a user for any losses caused for the
        violation of this clause 13. Furthermore, the company reserves the right to terminate, restrict your account,
        including but not limited to the users access to virtual items and pursue legal action against you, both civil
        and criminal in nature for violation of the terms and conditions of this Agreement.
      </p>
      <p>12.12 You bear the responsibility over your account, please check your account carefully before
        recharging/using the same on our platform. You shall bear all losses on account of incorrect account input,
        improper operation or lack of understanding of charging methods, etc., and we will not make any compensation for
        your losses.
      </p>
      <p>12.13 Unless otherwise regulated by law(s), all purchases of virtual items made through the service are final,
        non-refundable and non-transferrable under any circumstances. For avoidance of doubt, with respect to any
        services, products purchased by you, you are not allowed to a refund or transfer the virtual items to other
        services and/or products.
      </p>
      <p>12.14 The Company reserves the right to set out or modify the rules in connection with the virtual items and
        other transaction from time to time based on various factors including, but not limited to the transaction
        limits, number of transactions etc.
      </p>
      <p>12.15 We do not encourage or allow minors to use or buy virtual items. If you are a minor, you should ask your
        guardian(s) to operate or operate the website/service, on your behalf, with their express consent.
      </p>
      <p>12.16 You shall be responsible for losses arising from our inability to provide virtual currency Service or any
        issues in providing virtual items caused by the following reasons:
      </p>
      <p>12.16.1 Due to loss, ban or freeze of your account;
      </p>
      <p>12.16.2 you disclosed your password to others;
      </p>
      <p>12.16.3 Due to faults of payment channel institution;
      </p>
      <p>12.16.4 Caused by intentional or gross negligence or violation of laws and regulations of other users;
      </p>
      <p>12.16.5 Other reasons caused by yourself;
      </p>
      <p>12.17 You may receive some virtual gains in the course of using our services/application. In accordance with
        the terms of this agreement, you are only allowed to use the virtual items on the company’s platform. We reserve
        the right of final interpretation on such virtual gains and related policy terms. We have the right to adjust
        virtual gains policies from time to time without prior notice. You agree to be bound by the latest Policies as
        disclosed by us.
      </p>
      <p>12.18 Under the following circumstances, we reserve the right to deduct your corresponding virtual gains:
      </p>
      <p>12.18.1 If the recharge of the virtual currency is determined by the bank, payment channel, or court as
        invalid;
      </p>
      <p>12.18.2 The Chargeback or refund initiated by end user of this Platform is permitted subject to decision of
        Banks, payment gateway, courts, arbitration tribunal, or us;
      </p>
      <p>12.18.3 The money used for recharge is considered illegal or improper gains, and we are required by the court
        and other institutions to refund;
      </p>
      <p>12.18.4 Other circumstances which cause the virtual currency to be returned or refund.
      </p>
      <p>12.19 In the event of any violation of this Agreement, laws and regulations by you, we reserve the right to
        temporarily or permanently suspend, freeze your account and deduct the remaining virtual items and virtual gains
        on your account partially or wholly, and not compensate your losses, damages.
      </p>
      <p>12.20 You acknowledge and agree that if you voluntarily cancel your account, your virtual items, virtual gains,
        virtual gift, game currency, etc. will be waived automatically. Furthermore, we will not return the
        corresponding cash value and will not make any compensation(s).
      </p>
      <p>13. FORCE MAJEURE
      </p>
      <p>13.1 Any delay in or failure to perform any obligations by a Party under these Customer Terms will not
        constitute a breach of these Customer Terms to the extent caused by acts of any government authorities, acts of
        God, fire, flood, explosion, pandemic(s) riots, war, rebellion, insurrection or other event beyond the
        reasonable control of that Party (“Force Majeure”).
      </p>
      <p>14. TERMINATION
      </p>
      <p>14.1 These Customer Terms continues until such time as they are terminated by the company in accordance with
        terms hereunder.
      </p>
      <p>14.2 The Company may terminate these Terms with immediate effect without notice;
      </p>
      <p>14.2.1 In order to comply with any Applicable Law;
      </p>
      <p>14.2.2 You commit a breach of these or any other Customer Terms and Conditions/Policies/guidelines.
      </p>
      <p>14.3 You agree that the Company may at its sole discretion may terminate any account (or any part thereof). In
        addition, the company reserves the right to discontinue any aspect of the Service at any time, including but not
        limited to the right to discontinue the display of any licensed content, linked or embedded content etc.
      </p>
      <p> 14.4 The company shall in no event be responsible for the deletion, losing of, or failure to store your
        content. You agree that any termination of your access to the Service or any account you may have or portion
        thereof may be affected without prior notice, and you agree that the company will not be liable to you or any
        third party for such termination.
      </p>
      <p> 14.5 Any suspected fraudulent, abusive, or illegal activity that may be grounds for termination may be
        referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies the
        company may have in law or in equity.
      </p>
      <p>15. DISCLAIMERS; NO WARRANTIES.
      </p>
      <p> 15.1 Without limiting any other provision of this section and in addition to all other provisions of this
        section, to the fullest extent permitted by applicable law, the company expressly disclaims all warranties and
        conditions of any kind, either express or implied, including without limitation any implied warranties or
        conditions of merchantability, fitness for a particular purpose, non-infringement of third party rights, and
        those arising from a course of dealing or usage of trade, with respect to the services. The company makes no
        warranty that the services will meet your requirements, or that they will be uninterrupted, timely, secure, or
        error free. The company does not make any warranty or representation as to the use or the results that may be
        obtained from the use of their services.
      </p>
      <p> 15.2 You acknowledge that the services may be subject to operating errors or defects including, but not
        limited to loss of data, delays, non-deliveries, errors, system down time, mis-deliveries, network or system
        outages, file corruption, or service interruptions. No such event shall constitute a breach of this or any other
        contract on the part of the company, even if caused by the negligence or gross negligence of the company or any
        of its affiliates, employees, agents, licensors or subcontractors.
      </p>
      <p>15.3 Certain state laws do not allow limitations or implied warranties or the exclusion or limitation of
        certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations
        may not apply to you, in such cases, the company’s liability will be limited to the extent permitted by law.
      </p>
      <p>16. INDEMNIFICATION
      </p>
      <p>16.1 You agree to indemnify and hold the company, and its affiliated companies, and its suppliers and partners,
        harmless from any claims, losses, damages, liabilities, including attorney’s fees, arising out of your use or
        misuse of the Service, violation of these Terms, violation of the rights of any other person or entity, or any
        breach of the foregoing representations, warranties, and covenants.
      </p>
      <p>16.2 The company reserves the right, at our own expense, to assume the exclusive defence and control of any
        matter for which you are required to indemnify us and you agree to cooperate and indemnify us in our defence of
        these claims.
      </p>
      <p>17. LIMITATION OF LIABILITY
      </p>
      <p> 17.1 Under no circumstances, including, but not limited to, negligence, shall the company or its affiliates,
        contractors, employees, agents, or third party partners or suppliers, be liable to you for any special,
        indirect, incidental, consequential, or exemplary damages that result from your use or the inability to use the
        company materials or the service itself, or any other interactions with the company, even if the company or an
        authorized representative has been advised of the possibility of such damages. Applicable law may not allow the
        limitation or exclusion of liability or incidental or consequential damages, so the above limitation or
        exclusion may not apply to you. In such cases, the company’s liability will be limited to the extent permitted
        by law.
      </p>
      <p> 17.2 In no event shall the company or its affiliates, contractors, employees, agents, or third party partners
        or suppliers' total liability to you for all damages, losses, and causes of action arising out of or relating to
        these terms or your use of the company services (whether in contract, tort, warranty, or otherwise) exceed the
        amount already spent by a particular user on the platform.
      </p>
      <p> 17.3 These limitations shall also apply with respect to damages incurred by reason of any products or services
        sold or provided to you by third parties other than the company and received by you through or advertised on the
        company service or received by you through any links provided on the company service.
      </p>
      <p>18. AMENDMENTS TO THESE CUSTOMER TERMS
      </p>
      <p> 18.1 The Company may amend the terms of these Customer Terms and shall keep you updated regarding such
        amendments.
      </p>
      <p> 18.2 We may change these Terms at any time by updating them on the Website. Unless stated otherwise, any
        change takes effect immediately. You are responsible for ensuring you are familiar with the latest Terms. By
        continuing to access and use the Website, you agree to be bound by the changed Terms.
      </p>
      <p> 18.3 We may change, suspend, discontinue, or restrict access to, the Website without notice or liability.
      </p>
      <p> 18.4 These Terms were last updated on 01st May 2022.
      </p>
      <p>19. MISCELLANEOUS
      </p>
      <p> 19.1 Waiver: The provision(s) of these Terms may be waived only by a written instrument executed by the party
        entitled to the benefit of such provision. The failure of any party at any time to require performance of any
        provision of these Terms shall in no manner affect such party’s right at a later time to enforce the same. A
        waiver of any breach of any provision of these Terms shall not be construed as a continuing waiver of other
        breaches of the same or other provisions of these Terms.
      </p>
      <p> 19.2 Assignment: The Company shall be permitted to assign, transfer, and subcontract its rights and/or
        obligations under these Terms without any notification or consent. However, you shall not be permitted to
        assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
      </p>
      <p> 19.3 Severability: Any provision that is prohibited or unenforceable in any jurisdiction will, as to such
        jurisdiction, be ineffective to the extent of such prohibition or unenforceability without invalidating the
        remaining provisions or affecting the validity or enforceability of such provision in any other jurisdiction.
      </p>
      <p> 19.4 No warranties: This Website is provided “as is,” with all faults, and the company makes no express or
        implied representations or warranties, of any kind related to this Website or the materials contained on this
        Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to
        you.
      </p>
      <p> 19.5 Rights cumulative: Subject to any express provision in these Customer Terms to the contrary, the rights,
        powers or remedies of a Party under these Customer Terms are cumulative and in addition to, and do not exclude
        or limit, any right, power or remedy in any other part of these Customer Terms or otherwise provided at law or
        in equity.
      </p>
      <p>20. Legal Jurisdictions
      </p>
      <p> The company shall be governed by and construed in accordance with the laws of INDIA.Any dispute, controversy,
        difference or claim arising out of or relating to this Agreement, including the existence, validity,
        interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations
        arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the
        INDIA International Arbitration Centre (“IIAC”) under the IIAC Administered Arbitration Rules in force when the
        Notice of Arbitration is submitted.</p>




    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Detail from '@/components/Detail/Detail.vue'
import Footer from '@/components/Footer/Footer.vue'
export default {
  components: {
    Detail,
    Footer
  }

}
</script>

<style>

</style>