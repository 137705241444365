<template>
  <div class="tos">
    <Detail :deteil_title="'Cancellation Policy'"></Detail>
    <div class="wrap detail_text">
      <p>Our cancellation policy:</p>
      <p>Client satisfaction lies at the core of our services and we ensure that in every possible way. At Baat app,
        even our cancellation and refund policy of in-app purchases and virtual items are very transparent and different
        for each service we offer.</p>
      <p>How is the cancellation procedure?</p>
      <p>Send us your cancellation request by telephone, email or fax</p>
      <p>It will be collected at our accounts and billing department</p>
      <p>You will receive a confirmation reply from us. Until then, the request will remain invalid</p>
      <p>The email we send in reply will be considered proof of canceling the service</p>
      <p>Note: Clients need to place their cancellation request within 12 hours of placing the order. Baat will only
        accept it when the project has not been started yet.</p>

    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Footer from '@/components/Footer/Footer.vue'
import Detail from '@/components/Detail/Detail.vue'
export default {
  components: {
    Detail,
    Footer
  }

}
</script>

<style>
</style>