<template>
  <div class="mobile_content">
    <div class="bg1">
      <div class="moblieinfous_box" id="about">
        <img src="../img/mobileboximg1.png">
        <div class="mobiletext">
          <p class="mobile_texttitle" style="margin-bottom: .12rem;">Who Are We?</p>
          <p class="mobile_sectexttitle" style="margin-bottom: .16rem;">StarMake - A Community Built On Voice.</p>
          <p class="mobile_textcontent">India’s biggest voice-centric and entertainment platform. We not only
            provide you with a platform to connect but to voice out your thoughts and talents; to hear and be heard. You
            can also try to look for your new friends in StarMake.</p>
          <p style="color:rgba(48, 31, 55, 1);font-size: .12rem;font-family: Catamaran-Bold;">FELICE CASA TECHNOLOGY PTE. LTD.</p>
        </div>
      </div>
    </div>
    <div class="bg2">
      <div class="mobileinfocan_box">
        <img src="../img/mobileboximg2.png">
        <div class="mobiletext">
          <p class="mobile_texttitle" style="margin-bottom: .12rem;">What you can do on StarMake?

            ALL OF THESE!!</p>
          <p class="mobile_sectexttitle" style="margin-bottom: .12rem;">🎉 Voice Room🎉</p>
          <p class="mobile_textcontent">Party  in your favourite room. Join group voice room or create your own live.</p>

          <p class="mobile_sectexttitle" style="margin-bottom: .12rem;">💬 Private Converstation💬</p>
          <p class="mobile_textcontent">Start voice with friends to talk about your interests.</p>
          <p class="mobile_textcontent">Enjoy yourself! StarMake wish everyone in our community to showcase yourself,
            and stay connected in a free, friendship, and respect way.</p>
        </div>
      </div>
    </div>

    <div class="bg3">
      <div class="mobilechatnow_box">
        <p class="mobile_texttitle" style="margin-bottom:.24rem;text-align: center;">Let's Play Now</p>
        <div class="mobileswp">
          <!-- :interval="1000000"  -->
          <el-carousel type="card" height="3.5rem" class="paly_carousel" >
            <el-carousel-item v-for="(item) in carImgList" :key="item">
              <img :src="item" class="medium" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="bg4">
      <div class="mobilecontactus_box" id="contact">
        <p class="mobile_texttitle" style="margin-bottom:.24rem;text-align: center;">Contact Us </p>
        <div class="mobileconbody">
          <div class="input_item marbtom">
            <!-- <img src="../img/user_input.png"> -->
            <input type="text" placeholder="Please enter name" v-model="insertInfo.user" />
          </div>
          <div class="input_item marbtom">
            <!-- <img src="../img/email_input.png"> -->
            <input type="text" placeholder="Please enter E-mail" v-model="insertInfo.email" />
          </div>
          <div class="input_item marbtom">
            <!-- <img src="../img/phone_input.png"> -->
            <input type="text" placeholder="Please enter Phone" v-model="insertInfo.phone" />
          </div>
          <textarea class="message_input marbtom" placeholder="Please enter Message"
            v-model="insertInfo.message"></textarea>
          <el-button class="send marbtom" :plain="true" @click="thro">Send</el-button>
          <div class="info_text">
            <p><span>Customer care number:  </span><span>+65 62090077</span></p>
            <p style="margin:.12rem 0"><span>Customer care email ID: </span><span>contact@starmake.app</span></p>
            <p style="font-size: .14rem;">FELICE CASA TECHNOLOGY PTE. LTD.</p>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      carImgList: [
        require('../img/car_img1.png'),
        require('../img/car_img2.png'),
        require('../img/car_img3.png')
      ],
      insertInfo: {
        user: '',
        email: '',
        phone: '',
        message: ''
      },
      timer: null,
      flag: false
    }
  },
  watch: {
    "$route": {
      handler(val) {
        if (this.$route.query["mobilecontact"]) {
          this.toConcatUs()
        }
        if (this.$route.query["mobileabout"]) {
          this.toAboutus()
        }
      }
    }
  },
  mounted() {
    if (this.$route.query["mobilecontact"]) {
      this.toConcatUs()
    }
    if (this.$route.query["mobileabout"]) {
      this.toAboutus()
    }

  },
  methods: {

    toConcatUs() {

      document.getElementById(`contact`).scrollIntoView()
      this.$router.push('/mobile')
    },
    toAboutus() {
      document.getElementById(`about`).scrollIntoView()
      this.$router.push('/mobile')
    },

    throttle(func, wait = 300, immediate = true) {
      if (immediate) {
        if (!this.flag) {
          this.flag = true;
          // 如果是立即执行，则在wait毫秒内开始时执行
          typeof func === 'function' && func();
          this.timer = setTimeout(() => {
            this.flag = false;
          }, wait);
        }
      } else {
        if (!flag) {
          this.flag = true
          // 如果是非立即执行，则在wait毫秒内的结束处执行
          this.timer = setTimeout(() => {
            this.flag = false
            typeof func === 'function' && func();
          }, wait);
        }

      }
    },
    thro() {
      this.throttle(this.SendMessage, 60000)
    },

    SendMessage() {
      console.log('sendddd');
      this.insertInfo.user = ''
      this.insertInfo.email = ''
      this.insertInfo.phone = ''
      this.insertInfo.message = ''
      this.$message({
        message: 'send successfully',
        type: 'success'
        //type: warning
        // this.$message.error('shibai');
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./mobilecontent.scss">

</style>